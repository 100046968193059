import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import withTranslations from '../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title='Error 404' />
      <h1>{t('404.error')}</h1>
      <p>{t('404.notFound')}</p>
    </Layout>
  );
};

export default withTranslations()(NotFoundPage);
